import React from 'react';

import Layout from '../components/layout/Layout';
import MetaData from '../components/seo/MetaData';
import Container from '../components/layout/Container';
import SmallContainer from '../components/layout/SmallContainer';
import FirmanavnImage from '../components/images/FirmanavnImage';
import OrganizationStructuredData from '../components/seo/OrganizationStructuredData';

const Home: React.FunctionComponent = () => {

  return (
    <Layout>
      <MetaData title="Firmanavn" />
      <OrganizationStructuredData/>
      <Container className="pt-4 text-center">
        <h1 className="mb-0">Firmanavn</h1>
        <p className="mt-2">Find et ledigt firmanavn til din virksomhed</p>
        <FirmanavnImage />
      </Container>
      <SmallContainer className="text-content">
        <p>
          Et godt firmanavn er rigtig vigtigt for din virksomhed. 
          Det har indflydelse på mange dele af din forretning og kan få stor betydning, hvis din virksomhed vækster.
          Et er de vigtigste elementer ved firmanavnet er, at mennesker kan huske det og relatere det til din virksomhed.
          Mange navne er i dag endda blevet synonym med en specifik branche, hvilket kan være altafgørende.
        </p>
        <h2>Hvilke metoder kan du bruge til at finde et firmanavn?</h2>
        <p>
          Der er mange forskellige måder at finde et firmanavn på.
          Det er ikke altid den samme metoder der virker.
          Folk gør sig ofte nogle personlige erfaringer og herefter går de med den metode, der virker for dem.
          Her beskriver vi dog nogle af de populære metoder, som folk ofte har haft succes med.
        </p>
        <h4>Skriv dine forslag ned og undersøg dem</h4>
        <p>
          Du skal starte med at skrive alle mulige forskellige forslag ned på et stykke papir.
          Herefter skal du gå igennem alle dine forslag og finde de mest relevante.
          I denne fase skal du ikke vælge mere end en håndfuld.
          Når du har valgt disse skal du begynde at undersøge dem lidt nærmere og se om de reelt set er mulige firmanavne.
          Dette inkluderer at tjekke om andre virksomheder bruger navnet, om der er rettigheder forbundet med navnet samt om domænet er ledigt.
        </p>
        <p>
          Dette vil helt sikkert vise, at nogle af dine forslag ikke er aktuelle.
          Herefter skal du beslutte om de sidste er gode valg eller om du skal gå tilbage til den første fase og lede efter flere forslag.
          Du kan fortsætte med denne metode indtil du har fundet et godt firmanavn.
        </p>
        <h4>Start med at finde domænet</h4>
        <p>
          Det gør det ofte sværere, at mange domæner er optaget.
          Derfor anbefaler mange, at man starter med at kigge på firmanavne blandt de ledige domæner.
          På denne måde kan du undgå skuffelsen ved at finde et godt firmanavn, hvor domænet ikke er ledigt.
          Du kan altid tjekke om dit domæne er ledigt på siden: <a href="https://www.firma-navn.dk/">Firmanavn</a>.
        </p>
        <h2>Kan du overtage navne/domæner fra andre?</h2>
        <p>
          Dette er en af de mere utraditionelle måder at finde dig et firmanavn på. 
          Det betyder generelt rigtig meget at have et godt domæne.
          Derfor er det ofte et godt tip at tjekke for ledige domæner før du beslutter dig at gå videre med et specifikt navn.
        </p>
        <p>
          Navnet behøver dog ikke altid at være ledigt før du kan bruge det.
          Du har nemlig muligheden for at klage over et domæne og overtage det.
          Du kan dog ikke gøre det med et hvilket som helst domæne.
        </p>
        <h5>Hvornår kan man ikke overtage et domæne/firmanavn?</h5>
        <p>
          Som udgangspunkt skal du have et bedre krav på domænet end den nuværende ejer.
          Du kan selvfølgelig ikke bare overtage et domæne, som du gerne vil have, hvis den nuværende ejer bruger det fornuftigt.
          For eksempel kan du ikke overtage dr.dk, da Danmarks Radio bruger domænet aktivt.
          Du kan tydeligt se aktiviteten, når du går ind på siden. 
          Udover aktiviteten har de sikret sig diverse rettigheder til navnet, som gør dem til de retmæssige ejere.
        </p>
        <h5>Hvornår kan man overtage et domæne/firmanavn?</h5>
        <p>
          Kig efter et domæne, som ikke har tydelig aktivitet.
          Det kan være, at den nuværende ejer slet ikke har en hjemmeside vist.
          Det vil altså bare skrives, at "Siden ikke kan vises", hvis du skriver den i din browser.
          Dette giver dig gode muligheder for at overtage domænet, hvis du selv arbejder i den samme industri.
          Et godt eksempel vil være, at du ejer bussen.dk og gerne vil overtage bus.dk. 
          Bus.dk domænet er ejet af en anden, men denne ejer bruger slet ikke domænet.
          Her kan du klage over domænet og begynde en sag for at overtage det.
        </p>
        <h5>Hvor kan jeg få hjælp til at klage over domæne/firmanavn?</h5>
        <p>
          Du kan selv varetage din sag og klage over et domæne. 
          Det kræver dog, at du har sat dig grundigt ind i tingene.
          Modparten kan muligvis selv bringe en advokat ind i sagen.
        </p>
        <p>
          En af de gode måder at få hjælp er at bruge <a href="https://www.klagen.dk">Klagen - Klage over domæne</a>.
          Her kan du få en vurdering af dine muligheder for at overtage et specifikt domæne.
          Hvis dine muligheder er gode vil din sag blive ført og du har gode chancer for at overtage domænet.
          Desuden skal du kune betale for sagen, hvis du overtager domænet!
          Så du har ingen risiko ved at føre en sag.
        </p>
      </SmallContainer>
    </Layout>
  );
};

export default Home;
